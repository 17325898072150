"use client"

import React, { useState } from 'react'
import { ArrowDownward, ArrowUpward } from "@material-ui/icons"
import IHeading from '../IHeading'
import IParagraph from '../IParagraph'

interface FAQItem {
  question: string
  answer: string
}

interface FAQsProps {
  faqs: FAQItem[]
}

export default function FAQs({ faqs }: FAQsProps) {
  const [openIndex, setOpenIndex] = useState<number | null>(null)

  const toggleFAQ = (index: number) => {
    setOpenIndex(openIndex === index ? null : index)
  }

  return (
    <div className="w-full container mx-auto my-24">
      <div  data-aos="fade-up">
      <IHeading align='left' size='two'>Frequently Asked Questions</IHeading>
      </div>
      {faqs.map((faq, index) => (
        <div key={index}  className="my-3">
          <button
            className="flex justify-between items-center w-full text-left p-4 bg-secondary border border-gray-200 "
            onClick={() => toggleFAQ(index)}
            aria-expanded={openIndex === index}
            aria-controls={`faq-answer-${index}`}
          >
            <div data-aos="fade-up">
            <IHeading align='left' size="three">{faq.question}</IHeading>
            </div>
            {openIndex === index ? (
              <ArrowUpward className="w-5 h-5 text-gray-600" />
            ) : (
              <ArrowDownward className="w-5 h-5 text-gray-600" />
            )}
          </button>
          {openIndex === index && (
            <div
            data-aos="fade-up"
              id={`faq-answer-${index}`}
              className=" p-4 bg-gray-200  "
            >
              <IParagraph align='left'>{faq.answer}</IParagraph>
            </div>
          )}
        </div>
      ))}
    </div>
  )
}