import IHeading from "@/components/IHeading"
import ILine from "@/components/ILine"
import IParagraph from "@/components/IParagraph"
import Layout from "@/components/Layout"
import PageHeading from "@/components/PageHeading"
import PageIntro from "@/components/PageIntro"
import Seo from "@/components/seo"
import { graphql, Link, PageProps } from "gatsby"
import * as React from "react"
import img1 from "../images/vectors/undraw_Maintenance_re_59vn.svg"
import img2 from "../images/vectors/undraw_Thought_process_re_om58.svg"
import img3 from "../images/vectors/undraw_Development_re_g5hq.svg"
import img6 from "../images/vectors/undraw_server_cluster_jwwq.svg"
import { DeviceHub } from "@material-ui/icons"
import { Weekend } from "@material-ui/icons"
import { Brush } from "@material-ui/icons"
import { Fastfood } from "@material-ui/icons"
import { ShoppingBasket } from "@material-ui/icons"
import { StaticImage } from "gatsby-plugin-image"
import { PeopleOutline } from "@material-ui/icons"
import { Timeline } from "@material-ui/icons"
import { HeadsetMic } from "@material-ui/icons"
import Testimonial from "@/components/Testimonial"
import FAQs from "@/components/FAQs"
import CallToAction from "@/components/CallToAction"


interface EcommerceData {
    mdx: {
      body: string
      excerpt: string
      frontmatter: {
        title: string
        heading: string
        iconName: string
        color?: string
      }
    }
  }

  const EcommercePage: React.FC<PageProps<EcommerceData>> = ({ data }) => {
    const { frontmatter,  excerpt } = data.mdx
    const { title, heading,  } = frontmatter
    const feature = [
        {
          title: "Custom ecommerce website development",
        },
        {
          title: " Platform migration and integration",
        },
        {
          title: "Mobile commerce optimization",
        },
        {
          title: "Payment gateway setup and security",
        },
        {
          title: "Product catalog management",
        },
        {
          title: "SEO and content marketing for ecommerce",
        },
        {
          title: "Conversion rate optimization",
        },
        {
          title: "Analytics and reporting",
        },
      ]
      const Process = [
        {
          title: "Discovery and Planning",
          content:
            "We begin by understanding your business goals, target audience, and unique selling propositions. This phase includes market research, competitor analysis, and creating a detailed project roadmap.",
        },
        {
          title: "Design and Development",
          content:
            "Our team of skilled designers and developers create visually stunning, user-friendly ecommerce solutions tailored to your brand. We focus on responsive design, intuitive navigation, and seamless checkout processes.",
        },
        {
          title: "Testing and Quality Assurance",
          content:
            "Before launch, we rigorously test your ecommerce platform across various devices and scenarios to ensure a bug-free, smooth user experience.",
        },
        {
          title: "Launch and Post-launch Support",
          content:
            "We manage the deployment of your ecommerce site and provide ongoing support and maintenance to keep your online store running smoothly and securely.",
        },
      ]
      const Core = [
        {
          title: "Custom Ecommerce Development",
          content:
            "Tailor-made online stores that reflect your brand identity and meet your specific business requirements.",
          imgSrc: img1,
        },
        {
          title: "Ecommerce SEO and Marketing",
          content:
            "Comprehensive digital marketing strategies to increase your online visibility, drive qualified traffic, and boost conversions.",
          imgSrc: img2,
        },
        {
          title: "Ecommerce Analytics and Optimization",
          content:
            "Data-driven insights and continuous optimization to improve user experience, increase conversions, and maximize ROI.",
          imgSrc: img3,
        },
        {
          title: "Payment Integration and Security",
          content:
            "Seamless integration of secure payment gateways and implementation of robust security measures to protect customer data.",
          imgSrc: img6,
        },
      ]
      const helpItems = [
        {
          icon: <ShoppingBasket />,
          content: "Fashion and Apparel",
        },
        {
          icon: <DeviceHub />,
          content: " Electronics and Technology",
        },
        {
          icon: <Weekend />,
          content: " Home Goods and Furniture",
        },
        {
          icon: <Brush />,
          content: "Beauty and Cosmetics",
        },
        {
          icon: <Fastfood />,
          content: "Food and Beverage",
        },
      ]
      const items = [
        {
          imgSrc: <PeopleOutline />,
          title: "Expert Team",
          desc: " Our seasoned professionals have years of experience in ecommerce development and digital marketing.",
        },
        {
          imgSrc: <Timeline />,
          title: "Proven Track Record",
          desc: "We've helped numerous businesses achieve significant growth in their online sales and market presence.",
        },
        {
          imgSrc: <HeadsetMic />,
          title: "Client-Centric Approach",
          desc: " We prioritize your business goals and work closely with you throughout the process.",
        },
      ]
      const faqData = [
        {
          question: " How long does it take to build an ecommerce website?",
          answer:
            "The timeline varies depending on the complexity of the project, but typically ranges from 8 to 12 weeks for a fully custom solution.",
        },
        {
          question:
            "  Can you help with migrating my existing online store to a new platform?",
          answer:
            " Yes, we specialize in seamless platform migrations, ensuring no loss of data or disruption to your business",
        },
        {
          question: " Do you offer ongoing support after the website launch?",
          answer:
            "Absolutely. We provide various post-launch support and maintenance packages to keep your ecommerce site running smoothly.",
        },
        {
          question: "How do you ensure the security of online transactions?",
          answer:
            "We implement industry-standard security measures, including SSL certificates, PCI DSS compliance, and regular security audits.",
        },
        {
          question:
            "  Can you integrate my ecommerce site with my existing business systems?",
          answer:
            "Yes, we can integrate your ecommerce platform with various business systems like ERP, CRM, and inventory management software.",
        },
      ]
      return (
        <>
          <Layout>
            <Seo title={title + ` | Services`}
            description="Transform your online presence with our full-suite ecommerce solutions. From development to optimization, we've got your digital commerce needs covered." />
            <div data-aos="fade-up">
              <PageHeading title={title} description={heading}></PageHeading>
            </div>
            <div data-aos="fade-up">
              <PageIntro>{excerpt}</PageIntro>
            </div>
            <section className="w-full my-20">
              <div className="container mx-auto">
                <div className="max-w-5xl" data-aos="fade-up">
                  <IParagraph>
                    At Easesol, we understand that ecommerce is more than just
                    selling products online—it's about creating seamless, engaging
                    shopping experiences that drive growth and foster customer
                    loyalty. Our comprehensive approach combines cutting-edge
                    technology with data-driven strategies to help businesses of all
                    sizes thrive in the digital marketplace.
                  </IParagraph>
                </div>
              </div>
            </section>
    
            <section className="w-full my-24">
              <div className="container mx-auto">
                <div className="w-full">
                  <div>
                    <div data-aos="fade-up">
                      <IHeading align="left">
                        We offer a full range of ecommerce services, including:
                      </IHeading>
                    </div>
                  </div>
                  <div className="grid grid-cols-1 py-10 -mx-1 overflow-hidden md:grid-cols-2 gap-x-10">
                    {feature.map((item: any, index: number) => {
                      return (
                        <div
                          className="relative w-full px-6 my-6 overflow-hidden group"
                          key={index}
                          data-aos="fade-up"
                        >
                          <IHeading size="three">{item.title}</IHeading>
                          <IParagraph>{item.content}</IParagraph>
                          <div
                            className="absolute top-0 bottom-0 left-0 w-3 duration-300 bg-primary-200 dark:bg-primary-700 group-hover:w-full"
                            style={{ zIndex: "-1" }}
                          ></div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </section>
            <section className="w-full mt-24">
              <div className="container mx-auto work-flow-container">
                <div className="mb-2" data-aos="fade-up">
                  <IHeading size="subTitle">Our Process</IHeading>
                </div>
                <ILine align="left" />
                <div className="grid w-full grid-cols-1 gap-16 py-16 mx-auto work-flow-box md:grid-cols-2 2xl:grid-cols-3 md:gap-10">
                  {Process.map((item: any, index: number) => {
                    return (
                      <div
                        className="relative flex flex-col items-center justify-center p-16 text-3xl text-center text-white shadow-xl group work-flow-item rounded-xl"
                        data-aos="fade-up"
                      >
                        {item.title}
                        <div className="absolute top-0 bottom-0 left-0 right-0 flex items-center px-4 py-6 text-base text-center duration-300 shadow-2xl opacity-0 rounded-xl bg-primary-50 dark:bg-primary-200 text-primary-500 dark:text-primary-600 lg:text-lg group-hover:opacity-100">
                          {item.content}
                        </div>
                        <div className="absolute left-0 right-0 flex items-center justify-center mx-auto text-3xl text-center border-4 border-white rounded-full w-14 h-14 bg-primary-700 -top-8 md:-top-5 md:right-auto md:-left-5">
                          {index + 1}
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </section>
            <section className="w-full my-16">
              <div className="container pb-8 mx-auto">
                <div data-aos="fade-up">
                  <IHeading align="left">Core Services</IHeading>
    
                  <div className="">
                    <ILine align="left" />
                  </div>
                </div>
    
                {Core.map((item: any, index: number) => {
                  return (
                    <>
                      <div
                        className={`flex flex-col ${
                          index % 2 === 1 ? "lg:flex-row-reverse" : "lg:flex-row"
                        } items-center mb-20 gap-x-5 md:gap-x-10 xl:gap-x-20 gap-y-10`}
                        key={index}
                      >
                        <div className="w-full lg:w-1/2">
                          <div data-aos="fade-up">
                            <IHeading size="two">{item.title}</IHeading>
                          </div>
                          <div data-aos="fade-up">
                            <IParagraph>{item.content}</IParagraph>
                          </div>
                        </div>
                        <div
                          className="w-full max-w-lg lg:w-1/2 lg:max-w-max"
                          data-aos="fade-up"
                        >
                          <img src={item.imgSrc} alt={item.title} />
                        </div>
                      </div>
                    </>
                  )
                })}
              </div>
            </section>
            <section className="w-full my-16">
              <div className="container mx-auto">
                <div data-aos="fade-up">
                  <IHeading align="text-center">Industry Expertise</IHeading>
                </div>
                <div data-aos="fade-up">
                  <IParagraph align="text-center">
                    We have successfully served clients across various industries,
                    including
                  </IParagraph>
                </div>
                <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-2 mt-14">
                  {helpItems.map((item: any, index: number) => {
                    return (
                      <div
                        className="relative flex flex-col items-center p-10 overflow-hidden border-b-2 rounded-md shadow-xl group border-slate-600 dark:border-primary-400 dark:bg-primary-900"
                        key={index}
                        data-aos="fade-up"
                      >
                        <div className="z-10 mb-6 startup-help-icon-box">
                          {item.icon}
                        </div>
                        <p className="absolute text-2xl lg:text-3xl  items-center text-center top-0 bottom-0 left-0 right-0 z-0 p-16  text-gray-200 duration-300 opacity-0 bg-slate-600 dark:bg-primary-600 group-hover:opacity-100 group-hover:z-20">
                          {item.content}
                        </p>
                      </div>
                    )
                  })}
                </div>
                <div className="py-12">
                  <div data-aos="fade-up">
                    <IHeading align="text-left" size="subTitle">
                      ISuccess Story: FashionForward
                    </IHeading>
                  </div>
                  <div data-aos="fade-up">
                    <IParagraph align="left">
                      We helped FashionForward, a boutique clothing retailer,
                      increase their online sales by 150% within six months of
                      launching their new ecommerce platform. Our tailored solution
                      included a mobile-first design, advanced product filtering,
                      and personalized product recommendations.
                    </IParagraph>
                  </div>
                  <div className="">
                    <ILine align="left" />
                  </div>
                </div>
              </div>
            </section>
            <section className="py-16 my-14 bg-primary-200 dark:bg-primary-600  rounded-xl">
              <div className="container mx-auto">
                <div className="max-w-2xl mx-auto" data-aos="fade-up">
                  <IHeading align="text-center">Technology Partners</IHeading>
                  <IParagraph align="text-center">
                    We work with leading ecommerce platforms to deliver the best
                    solutions for our clients
                  </IParagraph>
                </div>
                <ILine align="center" />
    
                <div
                  className="flex justify-around flex-wrap md:flex-nowrap md:justify-evenly gap-4 items-center space-y-2.5 pt-4 pb-8"
                  data-aos="fade-up"
                >
                  <div className="techstack-icon-box group item">
                    <Link to="https://www.shopify.com/" target="_blank">
                      <StaticImage
                        src="../images/techs-icons/shopify.svg"
                        className="black-stack-icon duration-200 group-hover:scale-125 "
                        alt="Shopify"
                      />
                    </Link>
                  </div>
                  <div className="techstack-icon-box group item w-26">
                    <Link to="https://www.bigcommerce.com/" target="_blank">
                      <StaticImage
                        src="../images/techs-icons/bigcommerce.svg"
                        className="black-stack-icon pointer-events-none duration-200 group-hover:scale-125 block dark:hidden"
                        alt="BigCommerce"
                      />
                    </Link>
                  </div>
                  <div className="techstack-icon-box group item">
                    <Link to="https://woocommerce.com/" target="_blank">
                      <StaticImage
                        src="../images/techs-icons/woocommerce.svg"
                        className="black-stack-icon pointer-events-none duration-200 group-hover:scale-125 block dark:hidden"
                        alt="WooCommerce"
                      />
                    </Link>
                  </div>
                  <div className="techstack-icon-box group item">
                    <Link
                      to="https://www.magentoassociation.org/home"
                      target="_blank"
                    >
                      <StaticImage
                        src="../images/techs-icons/magento.svg"
                        className="black-stack-icon pointer-events-none duration-200 group-hover:scale-125 block dark:hidden"
                        alt="Magento"
                      />
                    </Link>
                  </div>
                </div>
                <div data-aos="fade-up">
                  <IParagraph align="text-center">
                    We're also partnered with top-tier analytics, marketing, and
                    payment processing tools to enhance your ecommerce capabilities.
                  </IParagraph>
                </div>
              </div>
            </section>
            <section className="w-full my-20">
              <div className="container mx-auto">
                <div data-aos="fade-up">
                  <IHeading align="text-center">Why Choose Us</IHeading>
                </div>
                <div className="grid grid-cols-1 mb-12 lg:grid-cols-2 gap-x-10 gap-y-28 mt-36">
                  {items.map((item: any) => {
                    return (
                      <div
                        className="flex flex-col items-center  p-5 rounded-lg shadow-xl bg-slate-700"
                        data-aos="fade-up"
                      >
                        <div className="flex items-center justify-center mb-6 -mt-20 overflow-hidden rounded-full icon-box w-28 md:w-40 h-28 md:h-40 bg-slate-200">
                          {item.imgSrc}
                        </div>
                        <IHeading size="subHeader" color="white">
                          {item.title}
                        </IHeading>
                        <div className="mt-3 mb-6">
                          <ILine />
                        </div>
                        <IParagraph color="gray">
                          <ul className="list-disc space-y-3 px-16">
                            {item.desc
                              .split(".")
                              .filter((sentence: string) => sentence.trim() !== "") // Filter out any empty strings
                              .map(
                                (
                                  sentence: string,
                                  index: React.Key | null | undefined
                                ) => (
                                  <li key={index}>{sentence.trim()}.</li>
                                )
                              )}
                          </ul>
                        </IParagraph>
                      </div>
                    )
                  })}
                </div>
                <div data-aos="fade-up">
                  <IHeading align="text-left" size="subTitle">
                    Client Testimonial
                  </IHeading>
                </div>
                <Testimonial
                  review="Easesol transformed our online store into a powerhouse of sales. Their expertise in both design and marketing has been invaluable to our growth"
                  name="Sarah Johnson"
                  type="HomeStyle Decor"
                  position="CEO"
                  reviewLink="https://www.g2.com/products/posthog/reviews/posthog-review-4887279"
                  reviewLinkText="Unknown"
                />
              </div>
            </section>
            <FAQs faqs={faqData} />
            <CallToAction
              title="Ready to Revolutionize Your Online Business?"
              description="Transform Your Ecommerce Presence Today"
              cta="Get Started Now"
              ctaTo="/contact-us"
            />
          </Layout>
        </>
      )
  }

  export const query = graphql`
  query EcommercePageQuery {
    mdx(slug: { eq: "ecommerce" }) {
      body
      excerpt(pruneLength: 160)
      frontmatter {
        title
        heading
        iconName
        color
      }
    }
  }
`

export default EcommercePage